export default {
    data() {
        return {}
    },
    computed: {
        computedShowBigCoinSelectedList() {
            return (bigCoinItem) => {
                const name = bigCoinItem.coinKindName || ''
                const dynastyName = bigCoinItem.dynastyName || ''
                const coinCategory = bigCoinItem.coinCategory || ''
                const categoryListText = (bigCoinItem.dynastyClassifyList || []).map((item) => item.dynastyName).join('，')
                if (bigCoinItem.coinCategoryId === 5) {
                    return `${name}${coinCategory ? `-${coinCategory}` : ''}${categoryListText ? `（${categoryListText}）` : ''}`
                } else {
                    return `${name}${dynastyName ? `-${dynastyName}` : ''}`
                }
            }
        },
    },
    methods: {

    }
}